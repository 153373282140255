//
// Root
//

:root,
[data-theme="light"] {
    // Note: Custom variable values only support SassScript inside `#{}`.

    --prefix: --#{$prefix};

    // Colors
    //
    // Generate palettes for full colors, grays, and theme colors.

    @each $color, $value in $colors {
        --#{$prefix}#{$color}: #{$value};
    }

    @each $color, $value in $grays {
        --#{$prefix}gray-#{$color}: #{$value};
    }

    @each $color, $value in $theme-colors {
        --#{$prefix}#{$color}: #{$value};
    }

    @each $color, $value in $theme-colors-rgb {
        --#{$prefix}#{$color}-rgb: #{$value};
    }

    --#{$prefix}white-rgb: #{to-rgb($white)};
    --#{$prefix}black-rgb: #{to-rgb($black)};
    --#{$prefix}body-color-rgb: #{to-rgb($body-color)};
    --#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};

    // Fonts

    // Note: Use `inspect` for lists so that quoted items keep the quotes.
    // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
    --#{$prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
    --#{$prefix}font-monospace: #{inspect($font-family-monospace)};
    --#{$prefix}gradient: #{$gradient};

    // Root and body
    // scss-docs-start root-body-variables
    @if $font-size-root != null {
        --#{$prefix}root-font-size: #{$font-size-root};
    }
    --#{$prefix}body-font-family: #{$font-family-base};
    @include rfs($font-size-base, --#{$prefix}body-font-size);
    --#{$prefix}body-font-weight: #{$font-weight-base};
    --#{$prefix}body-line-height: #{$line-height-base};
    --#{$prefix}body-color: #{$body-color};

    @if $body-text-align != null {
        --#{$prefix}body-text-align: #{$body-text-align};
    }
    --#{$prefix}body-bg: #{$body-bg};
    // scss-docs-end root-body-variables

    --#{$prefix}heading-color: #{$headings-color};
    --#{$prefix}link-color: #{$link-color};
    --#{$prefix}link-hover-color: #{$link-hover-color};

    --#{$prefix}code-color: #{$code-color};
    --#{$prefix}highlight-bg: #{$mark-bg};

    @each $name, $value in $grid-breakpoints {
        --#{$prefix}breakpoint-#{$name}: #{$value};
    }

    // scss-docs-start root-border-var
    --#{$prefix}border-width: #{$border-width};
    --#{$prefix}border-style: #{$border-style};
    --#{$prefix}border-color: #{$border-color};
    --#{$prefix}border-color-translucent: #{$border-color-translucent};

    --#{$prefix}border-radius: #{$border-radius};
    --#{$prefix}border-radius-sm: #{$border-radius-sm};
    --#{$prefix}border-radius-lg: #{$border-radius-lg};
    --#{$prefix}border-radius-xl: #{$border-radius-xl};
    --#{$prefix}border-radius-2xl: #{$border-radius-2xl};
    --#{$prefix}border-radius-pill: #{$border-radius-pill};
    // scss-docs-end root-border-var

    --#{$prefix}form-control-bg: var(--#{$prefix}body-bg);

    --#{$prefix}chart-tooltip-bg: #{$chart-tooltip-bg};
}

[data-theme="dark"] {
    --#{$prefix}body-color: #{$body-color-dark};
    --#{$prefix}body-color-rgb: #{to-rgb($body-color-dark)};
    --#{$prefix}body-bg: #{$body-bg-dark};
    --#{$prefix}body-bg-rgb: #{to-rgb($body-bg-dark)};


    @each $color, $value in $theme-dark-colors {
        --#{$prefix}#{$color}: #{$value};
    }

    @each $color, $value in $theme-dark-colors-rgb {
        --#{$prefix}#{$color}-rgb: #{$value};
    }

    --#{$prefix}border-color: #{$border-color-dark};
}