//
// Avatar
//

.avatar {
    --#{$prefix}avatar-bg: #{$avatar-bg};
    --#{$prefix}avatar-color: #{$avatar-color};
    --#{$prefix}avatar-border-radius: #{$avatar-border-radius};

    position: relative;
    display: inline-block;
    width: $avatar-size-base;
    height: $avatar-size-base;
    flex-shrink: 0;
    font-size: calc($avatar-size-base / 2.75); 
    border-radius: var(--#{$prefix}avatar-border-radius);   
}
  
.avatar-img {
    display: inline-block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: var(--#{$prefix}avatar-border-radius);

    .avatar-circle & {
        border-radius: 50%;
    }
}

.avatar-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 0;
    pointer-events: none;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    background-color: var(--#{$prefix}avatar-bg);
    color: var(--#{$prefix}avatar-color);

    .avatar-circle & {
        border-radius: 50%;
    }
}


// Sizes
.avatar-xxs {
    width: $avatar-size-xxs;
    height: $avatar-size-xxs;
    font-size: calc($avatar-size-xxs / 2.75);
}

.avatar-xs {
    width: $avatar-size-xs;
    height: $avatar-size-xs;
    font-size: calc($avatar-size-xs / 2.75);
}
  
.avatar-sm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;
    font-size: calc($avatar-size-sm / 2.75);
}

.avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    font-size: calc($avatar-size-lg / 2.75);
}

.avatar-xl {
    width: $avatar-size-xl;
    height: $avatar-size-xl;
    font-size: calc($avatar-size-xl / 2.75);
}

.avatar-xxl {
    width: $avatar-size-xl;
    height: $avatar-size-xl;
    font-size: calc($avatar-size-xl / 2.75);

    @include media-breakpoint-up(md) {
        width: $avatar-size-xxl;
        height: $avatar-size-xxl;
        font-size: calc($avatar-size-xxl / 2.75);
    }
}


// Statuses
.avatar-online,
.avatar-busy,
.avatar-away,
.avatar-offline {

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30%;
        height: 30%;
        border-radius: 50%;
        border: 1px solid $white;
    }

    &.avatar-lg,
    &.avatar-xl {
        &::before {
            border-width: 1.5px;
        }
    }

    &.avatar-xxl {
        &::before {
            border-width: 2px;
        }
    }

    &.avatar-circle {
        .avatar-img {
            mask-image: url(escape-svg("data:image/svg+xml,<svg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><mask id='avatarStatus'><rect width='100' height='100' fill='white'></rect><circle cx='85' cy='85' r='15' fill='black'></circle></mask></defs><rect width='100' height='100' fill='green' mask='url(#avatarStatus)'></rect></svg>"));
            mask-size: 100% 100%;

            [dir="rtl"] & {
                transform: rotateY(180deg);
            }
        }
    }

}

.avatar-online::before {
    background-color: var(--#{$prefix}success);
}
  
.avatar-busy::before {
    background-color: var(--#{$prefix}danger);
}

.avatar-away::before {
    background-color: var(--#{$prefix}warning);
}

.avatar-offline::before {
    background-color: var(--#{$prefix}gray-500);
}


// Group
.avatar-group {
    display: inline-flex;
    align-items: center;
    z-index: 2;

    .avatar {
        &:hover {
            z-index: 2;
        }

        + .avatar {
            margin-left: -1em;
        }
    }
}