//
// highlight.js
//

@import "~highlight.js/scss/base16/rebecca";

.hljs {
    background-color: $gray-900;
}

// Line number style
.hljs-ln {
    td.hljs-ln-numbers {
        text-align: center;
        color: $gray-500;
        border-right: 1px solid $gray-500;
        vertical-align: top;
        padding-right: .5rem;
    
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    td.hljs-ln-code {
        padding-left: 1rem;
    }
    
    code {
        white-space: pre-wrap;
        overflow: auto;
    }
}