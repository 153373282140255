/**
 * Copy and paste the variables that...
 *
 * Example for the most commom variables:
 *
 * $primary: #d138f2;
 * $secondary: #ff0000;
 * $font-family-base: 'Merriweather', serif;
 */

$border-radius: 2px;
$btn-border-radius: 2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$grid-gutter-width: 1rem;

$table-th-font-size: var(--bs-body-font-size);

$offcanvas-horizontal-width: 500px;

$input-font-size: var(--bs-body-font-size);

$form-floating-height: add(3.5rem, 4px);

$card-margin-bottom: 0;
