/**
 * Define you custom style here.
 * It will be merged with the default style.
 */

.navbar-vertical ~ main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    //background-color: var(--bs-main-content-bg);

    /*.container,
    .container-fluid {
        @include media-breakpoint-up(xl) {
            padding-left: (!*$main-container-padding-x +*!
                $container-padding-x) !important;
            padding-right: (!*$main-container-padding-x +*!
                $container-padding-x) !important;
        }
    }*/
}

[data-sidebar-behaviour="fixed"] .sidebar-behaviour-toggle > * {
    transform: rotate(180deg);
}

nav#mainNavbar, main {
    transition: margin-left 0.2s;
}

nav#mainNavbar {
    transition: width 0.2s, max-width 0.2s;
}

[data-fetch] {
    cursor: pointer;
}


.table-hover > tbody > tr:hover > * {
    transition: $transition-base;
}


.table {
    > thead {
        th {
            border-bottom-width: $table-border-width;
        }
    }
}

.form-floating {
    .ts-wrapper {
        padding-top: 1.625rem !important;
        padding-bottom: 0 !important;
    }

    .ts-wrapper.focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }

    .ts-control {
        padding-top: 4px;
        padding-bottom: 8px;
    }

    .focus .ts-control {
        box-shadow: none;
        border: none;
    }

}

[data-theme="dark"] {
    color-scheme: dark;
}


